import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Button = makeShortcode("Button");
const BlogCard = makeShortcode("BlogCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Blog `}<div style={{
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between",
        "width": "100%"
      }}>{` `}<p>{`Here are some geeky stuff I like to talk about and some basic tutorials.`}</p><Link to="/blog" mdxType="Link"><Button style={{
            "fontSize": "0.5em",
            "cursor": "pointer"
          }} mdxType="Button">{`View All`}</Button></Link></div></h2>
    <>
      {props.data.allMarkdownRemark.edges.map(({
        node
      }, idx) => {
        if (idx < 4) {
          return <BlogCard title={node.frontmatter.title} link={node.fields.slug} bg={props.backgroundGradients[idx]} mdxType="BlogCard">
    {node.frontmatter.description}
    </BlogCard>;
        }
      })}
    </>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      