import React from "react";
import { Frame, Stack, Page, Scroll, useCycle } from "framer";

import { motion } from "framer-motion";
import Matrix from "./matrix-effect";
import { useThemeUI, useColorMode } from "theme-ui";
import { getColor } from "@theme-ui/color";
import Typist from "react-typist";

export default function IntroSplash() {
  const context = useThemeUI();
  const backgroundColor = getColor(context.theme, "background");
  return (
    <>
      <motion.div
        style={{
          backgroundColor: backgroundColor,
          color: "green",
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          position: "absolute",
          zIndex: 99999,
        }}
        animate={{
          opacity: 0,
        }}
        transition={{ delay: 2, duration: 0.5 }}
      >
        <Matrix />
      </motion.div>
      <motion.div
        style={{
          overflow: "hidden",
          position: "absolute",
          zIndex: 99999,
          color: "green",
          fontSize: "100px",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "courier",
        }}
        animate={{
          opacity: 0,
          y: "100vh",
        }}
        transition={{ delay: 2, duration: 0.5 }}
      >
        <Typist>Welcome</Typist>
      </motion.div>
    </>
  );
}
