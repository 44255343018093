import React from "react";
import { Link } from "gatsby";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons.cjs";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Hero from "@lekoarts/gatsby-theme-cara/src/components/hero";
import Projects from "@lekoarts/gatsby-theme-cara/src/components/projects";
import About from "@lekoarts/gatsby-theme-cara/src/components/about";
import Contact from "@lekoarts/gatsby-theme-cara/src/components/contact";

import Blog from "../../../components/blog";
import Skills from "../../../components/skills";
import Navbar from "../../../components/navbar";
import BlogCard from "../../../components/blog-card";
import SkillCard from "../../../components/skill-card";
import TimelineComponent from "../../../components/timeline";
import { MDXProvider } from "@mdx-js/react";
import { Button, Text, Grid } from "theme-ui";

const Timeline = React.memo(() => {
  return <TimelineComponent />;
});

const shortcodes = { BlogCard, Button, Link, Text, SkillCard, Grid, Timeline };

import { Helmet } from "react-helmet";
import IntroSplash from "../../../components/intro-splash";

const Body = React.memo(() => {
  return (
    <>
      <Hero offset={0} factor={1} />
      <Skills offset={1} factor={2} />
      <Blog offset={3} factor={1} />
      <Projects offset={4} factor={1} />
      <About offset={5} factor={1} />
      <Contact offset={6} factor={1} />
    </>
  );
});

const Cara = () => {
  const [parallax, setParallax] = React.useState();
  const [showSplash, setShowSplash] = React.useState(true);
  const showSplashRef = React.useRef(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
      showSplashRef.current = false;
    }, 2500);
  }, []);

  return (
    <MDXProvider components={shortcodes}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {showSplash && showSplashRef.current ? <IntroSplash /> : null}
      <Layout>
        <Navbar parallax={parallax} />
        <Parallax
          pages={7}
          ref={(ref) => {
            setParallax(ref);
          }}
        >
          <Body />
        </Parallax>
      </Layout>
    </MDXProvider>
  );
};

export default Cara;
