import React from "react";
import { useThemeUI, useColorMode } from "theme-ui";
import { getColor } from "@theme-ui/color";

export default function () {
  const context = useThemeUI();
  let backgroundColor = getColor(context.theme, "background");
  let textColor = "#0f0";
  if (backgroundColor.length === 4) {
    backgroundColor = "#ffffff";
    textColor = "forestgreen";
  }

  React.useEffect(() => {
    //https://dev.to/gnsp/making-the-matrix-effect-in-javascript-din
    // Get the canvas node and the drawing context
    const canvas = document.getElementById("canv");
    const ctx = canvas.getContext("2d");

    // set the width and height of the canvas
    // const w = (canvas.width = document.body.offsetWidth);
    // const h = (canvas.height = document.body.offsetHeight);

    const w = (canvas.width = 2000);
    const h = (canvas.height = 2000);

    // draw a black rectangle of width and height same as that of the canvas
    // ctx.fillStyle = "#000";
    // ctx.fillRect(0, 0, w, h);

    const cols = Math.floor(w / 20) + 1;
    // const ypos = Array(cols).fill(300);
    const ypos = [];
    for (let i = 0; i < cols; i++) {
      ypos.push(Math.random() * 2000);
    }

    function matrix() {
      // Draw a semitransparent black rectangle on top of previous drawing
      ctx.fillStyle = backgroundColor + "1A"; // add transparent background color
      console.log(backgroundColor);

      ctx.fillRect(0, 0, w, h);

      // Set color to green and font to 15pt monospace in the drawing context
      // ctx.fillStyle = "#0f0";
      ctx.fillStyle = textColor;
      ctx.font = "20pt monospace";

      // for each column put a random character at the end
      ypos.forEach((y, ind) => {
        // generate a random character
        const text = String.fromCharCode(Math.random() * 128);

        // x coordinate of the column, y coordinate is already given
        const x = ind * 20;
        // render the character at (x, y)
        ctx.fillText(text, x, y);

        // randomly reset the end of the column if it's at least 100px high
        if (y > 1000 + Math.random() * 10000) ypos[ind] = 0;
        // otherwise just move the y coordinate for the column 20px down,
        else ypos[ind] = y + 40;
      });
    }

    // render the animation at 20 FPS.
    let interval = setInterval(matrix, 25);
    return () => {
      clearInterval(interval);
      ctx.fillStyle = backgroundColor + "1A"; // add transparent background color
    };
  }, []);

  return <canvas id="canv" style={{ width: "100%", height: "100%" }}></canvas>;
}
