import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SkillCard = makeShortcode("SkillCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Skills and Interests`}</h2>
    <Grid columns={[1, 2, 4]} mdxType="Grid">
      <SkillCard src="https://img.icons8.com/doodle/96/000000/learning.png" label="Machine Learning" mdxType="SkillCard" />
      <SkillCard src="https://img.icons8.com/color/96/000000/python.png" label="Python" mdxType="SkillCard" />
      <SkillCard height="48" src="https://www.clipartmax.com/png/full/301-3016075_rstudio-icon-r-studio-logo-png.png" label="R" mdxType="SkillCard" />
      <SkillCard label="Javascript" src="https://img.icons8.com/color/96/000000/javascript.png" mdxType="SkillCard" />
      <SkillCard label="HTML" src="https://img.icons8.com/color/96/000000/html-5--v1.png" mdxType="SkillCard" />
      <SkillCard label="CSS" src="https://img.icons8.com/color/96/000000/css3.png" mdxType="SkillCard" />
      <div style={{
        "display": "flex",
        "alignItems": "center"
      }}>
  <SkillCard width="96" label="LaTeX" src="https://upload.wikimedia.org/wikipedia/commons/9/92/LaTeX_logo.svg" mdxType="SkillCard" />
      </div>
      <SkillCard label="AWS" src="https://img.icons8.com/color/96/000000/amazon-web-services.png" mdxType="SkillCard" />
      <SkillCard label="MySQL" src="https://img.icons8.com/color/96/000000/mysql-logo.png" mdxType="SkillCard" />
      <SkillCard label="PostgreSql" src="https://img.icons8.com/color/96/000000/postgreesql.png" mdxType="SkillCard" />
      <SkillCard label="MongoDB" src="https://img.icons8.com/color/96/000000/mongodb.png" mdxType="SkillCard" />
      <SkillCard label="git" src="https://img.icons8.com/windows/96/000000/git-squared.png" mdxType="SkillCard" />
      <SkillCard label="Excel" src="https://img.icons8.com/fluent/96/000000/microsoft-excel-2019.png" mdxType="SkillCard" />
      <SkillCard label="Tableau" src="https://img.icons8.com/color/96/000000/tableau-software.png" mdxType="SkillCard" />
      <div style={{
        "width": "96px"
      }}>
        <SkillCard width="48" label="PowerBI" src="https://www.silversands.co.uk/wp-content/uploads/Power-BI-icon-e1587658528784.png" mdxType="SkillCard" />
      </div>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      