import React from "react";
import styled from "styled-components";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

import { useThemeUI, useColorMode } from "theme-ui";
import { getColor } from "@theme-ui/color";

const WorkIcon = () => {
    return <FontAwesomeIcon style={{ marginLeft: -9 }} icon={faBriefcase} />;
};

const SchoolIcon = () => {
    return <FontAwesomeIcon icon={faGraduationCap} />;
};

export default function Timeline() {
    const [colorMode, setColorMode] = useColorMode();
    const isDark = colorMode === `dark`;
    const context = useThemeUI();
    const primaryColor = getColor(context.theme, "primary");
    const secondaryColor = getColor(context.theme, "secondary");
    const textColor = getColor(context.theme, "text");

    const VerticalTimelineStyled = styled(VerticalTimeline)`
    ::before {
      background-color: ${textColor};
    }
  `;

    return (
        <div style={{ position: "absolute", marginTop: "100px", width: 1000 }}>
            <VerticalTimelineStyled className="Timeline" datacolor="red">
                <VerticalTimelineElement
                    contentStyle={{
                        background: secondaryColor,
                        color: "#fff",
                    }}
                    contentArrowStyle={{ borderRight: `7px solid ${secondaryColor}` }}
                    date="June 2018"
                    iconStyle={{
                        background: secondaryColor,
                        color: "#fff",

                        boxShadow: `0 0 0 4px ${textColor}`,
                    }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">UCLA</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        B.S. Neuroscience, Statistics minor
              </h4>
                    <em>Where my passion for data science was born...</em>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{
                        background: primaryColor,
                        color: "#fff",
                    }}
                    contentArrowStyle={{ borderRight: `7px solid ${primaryColor}` }}
                    date="Apr 2020 - Oct 2020"
                    iconStyle={{
                        background: primaryColor,
                        color: "#fff",
                        boxShadow: `0 0 0 4px ${textColor}`,
                    }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Software Engineer Intern
          </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Psypher (Mental Health)
          </h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{
                        background: primaryColor,
                        color: "#fff",
                    }}
                    contentArrowStyle={{ borderRight: `7px solid ${primaryColor}` }}
                    date="Feb 2019 - Feb 2020"
                    iconStyle={{
                        background: primaryColor,
                        color: "#fff",

                        boxShadow: `0 0 0 4px ${textColor}`,
                    }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Busines Analyst</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Juvo+ (eCommerce)
          </h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{
                        background: primaryColor,
                        color: "#fff",
                    }}
                    contentArrowStyle={{ borderRight: `7px solid ${primaryColor}` }}
                    date="Feb 2020 - present"
                    iconStyle={{
                        background: primaryColor,
                        color: "#fff",
                        boxShadow: `0 0 0 4px ${textColor}`,
                    }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Data Scientist</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        Juvo+ (eCommerce)
            </h4>
                </VerticalTimelineElement>
            </VerticalTimelineStyled>
        </div>
    );
}
