import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Find me on other platforms: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/kenny-lov"
      }}>{`LinkedIn`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.github.com/clfkenny"
      }}>{`GitHub`}</a>{`, or email me at `}<a parentName="p" {...{
        "href": "mailto:lov.kenny.hq@gmail.com"
      }}>{`lov.kenny.hq@gmail.com`}</a></p>
    <p>{`I'd love to chat about machine learning applications or other exciting projects!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      