/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui";

const Footer = () => {
  return (
    <div></div>
    // <Box as="footer" variant="footer">
    //   Copyright &copy; {new Date().getFullYear()}. All rights reserved.
    //   <br />
    // </Box>
  );
};

export default Footer;
