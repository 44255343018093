import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Mini Projects`}</h2>
    <ProjectCard title="Sudoku Webcam Solver" link="https://webcam-sudoku-solver.netlify.app/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Use your webcam to solve your own sudoku puzzles (and view the image processing steps used)! It also includes a fully playable sudoku game where you can test your skills or observe how a backtracking algorithm can solve the game. <br />
Check out this Youtube <a href="https://www.youtube.com/watch?v=Zqv0GByeFOs" target="_blank">video</a> for more information.
    </ProjectCard>
    <ProjectCard title="Snake Q-Learning" link="https://github.com/clfkenny/snake_q_learning" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Train an AI agent to play the classic game of Snake using Q-Learning, a rudimentary, but important, reinforcement learning technique.
    </ProjectCard>
    <ProjectCard title="Quickdraw Sketch Recognizer" link="https://clfkenny.herokuapp.com/quickdraw" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
Used Google's Quick Draw dataset to train a Convolutional Neural Network to predict YOUR hand drawn sketches with ~90% accuracy!
    </ProjectCard>
    <ProjectCard title="Better Handwritten Digits Recognizer" link="https://clfkenny.herokuapp.com/better_digits" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
 Handwritten Digit Recognizer v2.0. Improved on the previous digit recognition by now adding a larger canvas to draw on. Also incorporated an object detection script to detect multiple digits drawn onto the canvas!
    </ProjectCard>
    <ProjectCard title="Handwritten Digit Recognizer" link="https://clfkenny.herokuapp.com/digits" bg="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(88,88,255,1) 32%, rgba(0,212,255,1) 100%)" mdxType="ProjectCard">
Trained a Convolutional Neural Network on the MNIST dataset to predict handwritten numbers with over 99% accuracy!
    </ProjectCard>
    <ProjectCard title="Text Sentiment Analyzer" link="https://clfkenny.herokuapp.com/text_sentiment" bg="linear-gradient(93deg, rgba(95,195,196,1) 0%, rgba(102,168,93,1) 100%)" mdxType="ProjectCard">
Using a multinomial naive bayes classifier to determine whether your text input is positive or negative! This small project was made mainly to practice deploying web apps with a machine learning backend.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      