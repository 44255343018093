import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`A little about me...`}</h2>
    <div style={{
      "fontSize": "1.25em",
      "display": "flex"
    }}>
      <div style={{
        "flex": "1",
        "marginRight": "70px"
      }}>
        <blockquote>
          <p parentName="blockquote">{`I love all things data.`}</p>
        </blockquote>
        <p>{`Hi! My name is Kenny and I studied at University of California, Los Angeles. I graduated June 2018 with a B.S. in Neuroscience and a minor in Statistics. I've always thought that I wanted to pursue medicine, but after being introduced to statistics, I've discovered my passion for data science/analysis and machine learning!`}</p>
        <p>{`I've since been working primarily in the eCommerce and Supply Chain field, where I apply statistical models to answer critical business questions. I also design and develop scalable systems that drive various processes across many different teams.`}</p>
      </div>
      <div style={{
        "position": "relative"
      }}>
        <div style={{
          "position": "absolute",
          "top": "50px",
          "left": "-650px",
          "height": "30px",
          "width": "30px",
          "backgroundColor": "orange",
          "clipPath": "polygon(28% 0, 12% 100%, 100% 57%)",
          "zIndex": "-1"
        }}></div>
        <div style={{
          "position": "absolute",
          "top": "-20px",
          "left": "-555px",
          "height": "50px",
          "width": "50px",
          "backgroundColor": "purple",
          "clipPath": "polygon(99% 1%, 0 40%, 74% 100%)",
          "zIndex": "-1"
        }}></div>
        <div style={{
          "position": "absolute",
          "top": "-50px",
          "left": "-405px",
          "height": "60px",
          "width": "60px",
          "backgroundColor": "blue",
          "clipPath": "polygon(100% 48%, 11% 14%, 22% 100%)",
          "zIndex": "-1"
        }}></div>
        <div style={{
          "position": "absolute",
          "top": "-30px",
          "left": "-245px",
          "height": "80px",
          "width": "80px",
          "backgroundColor": "green",
          "clipPath": "polygon(96% 13%, 0 29%, 62% 100%)",
          "zIndex": "-1"
        }}></div>
        <div style={{
          "position": "absolute",
          "top": "-70px",
          "left": "-105px",
          "height": "100px",
          "width": "100px",
          "backgroundColor": "red",
          "clipPath": "polygon(50% 100%, 0% 0%, 100% 0%)",
          "zIndex": "-1"
        }}></div>
        <div style={{
          "position": "absolute",
          "top": "-100px",
          "left": "-85px",
          "height": "350px",
          "width": "350px",
          "backgroundColor": "teal",
          "clipPath": "polygon(50% 0%, 0% 100%, 100% 100%)",
          "zIndex": "-1"
        }}></div>
        <div style={{
          "marginTop": "50px",
          "width": "250px",
          "height": "250px",
          "overflow": "hidden",
          "borderRadius": "50%",
          "verticalAlign": "middle"
        }}>
          <img style={{
            "width": "300px",
            "marginLeft": "-25px",
            "alignSelf": "center"
          }} src={`profile_img.jpg`} />
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      