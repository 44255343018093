/** @jsx jsx */
import { jsx } from "theme-ui";
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider";
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner";
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content";
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg";
import {
  UpDown,
  UpDownWide,
} from "@lekoarts/gatsby-theme-cara/src/styles/animations";
import { useStaticQuery, graphql } from "gatsby";
// @ts-ignore
import BlogMDX from "@lekoarts/gatsby-theme-cara/src/sections/blog.mdx";

const backgroundGradients = [
  "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)",
  "linear-gradient(to right, #009245 0%, #FCEE21 100%)",
  "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)",
  "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)",
];

const Blog = ({ offset, factor = 2 }: { offset: number; factor?: number }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { layout: { eq: "projects" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              author
              date
              title
              layout
              description
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <Divider
        bg="linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 80%, rgba(252,176,69,1) 100%)"
        clipPath="polygon(0 26%, 100% 14%, 100% 62%, 0 74%)"
        speed={0.2}
        offset={offset + 0.1}
        factor={factor}
      />
      <Content speed={0.4} offset={offset} factor={factor}>
        <Inner>
          <div
            sx={{
              display: `grid`,
              gridGap: [4, 4, 4, 5],
              gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
              h2: { gridColumn: `-1/1` },
            }}
          >
            <BlogMDX data={data} backgroundGradients={backgroundGradients} />
          </div>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <SVG
            icon="box"
            width={6}
            color="icon_brightest"
            left="85%"
            top="75%"
          />
          <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
          <SVG
            icon="triangle"
            width={8}
            stroke
            color="icon_orange"
            left="25%"
            top="5%"
          />
          <SVG
            icon="circle"
            hiddenMobile
            width={24}
            color="icon_brightest"
            left="17%"
            top="60%"
          />
        </UpDown>
        <UpDownWide>
          <SVG
            icon="arrowUp"
            hiddenMobile
            width={16}
            color="icon_green"
            left="20%"
            top="90%"
          />
          <SVG
            icon="triangle"
            width={12}
            stroke
            color="icon_brightest"
            left="90%"
            top="30%"
          />
          <SVG
            icon="circle"
            width={16}
            color="icon_yellow"
            left="70%"
            top="90%"
          />
          <SVG
            icon="triangle"
            hiddenMobile
            width={16}
            stroke
            color="icon_teal"
            left="18%"
            top="75%"
          />
          <SVG
            icon="circle"
            width={6}
            color="icon_brightest"
            left="75%"
            top="10%"
          />
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_green"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
        <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
        <SVG
          icon="hexa"
          width={16}
          stroke
          color="icon_red"
          left="75%"
          top="30%"
        />
        <SVG
          icon="hexa"
          width={8}
          stroke
          color="icon_yellow"
          left="80%"
          top="70%"
        />
      </Divider>
    </div>
  );
};

export default Blog;
