import React from "react";
import { Flex, Box, Text, Button, useColorMode, NavLink } from "theme-ui";

const Navbar = ({ parallax }) => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`);
  };

    const style = { cursor: "pointer", fontWeight:800 };
  return (
    <>
      <Flex
        as="nav"
        sx={{
          alignItems: "center",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 999,
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            filter: "blur(4px)",
              zIndex:-1
          }}
        />
        <Box p={3} sx={{ flex: "1 1 auto" }}></Box>
        <Box p={3}>
          <NavLink sx={style} onClick={() => parallax.scrollTo(0)}>
            Me
          </NavLink>
        </Box>
        <Box p={3}>
          <NavLink sx={style} onClick={() => parallax.scrollTo(1)}>
            Skills
          </NavLink>
        </Box>
        <Box p={3}>
          <NavLink sx={style} onClick={() => parallax.scrollTo(2)}>
            Blog
          </NavLink>
        </Box>
        <Box p={3}>
          <NavLink sx={style} onClick={() => parallax.scrollTo(3)}>
            Projects
          </NavLink>
        </Box>
        <Box p={3}>
          <NavLink sx={style} onClick={() => parallax.scrollTo(4)}>
            About
          </NavLink>
        </Box>
        <Box p={3}>
          <NavLink sx={style} onClick={() => parallax.scrollTo(5)}>
            Contact
          </NavLink>
        </Box>
        <Box p={3} mr={20}>
          <Button sx={style} onClick={toggleColorMode}>
            {isDark ? "Dark" : "Light"}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default Navbar;
