/** @jsx jsx */
import React from "react"
import { jsx, Box, Flex } from "theme-ui"

type SkillCardProps = {
  src: string
  label: React.ReactNode
    height: number
    width: number
}

const SkillCard = ({ src, label, height=48, width=48}: SkillCardProps) => (
  <Box
    sx={{
      width: `250px`,
      boxShadow: `lg`,
      position: `relative`,
      textDecoration: `none`,
      borderRadius: `lg`,
      px: 3,
      py: 2,
      color: `white`,
        background: "gray",
      /* background: bg || `none`, */
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    }}
  >
    <Flex sx={{alignItems: "center"}}>
      <Box sx={{flex: "1"}}><img style={{height: height+"px", width: width+"px"}}src={src}/></Box>
    <Box sx={{ opacity: 0.85, textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)` }}>{label}</Box>
    </Flex>
  </Box>
)

export default SkillCard
